<template>
	<div>
		<ContentHeader title="Jadwal Shift" url="/jadwal-shift" subTitle="Edit Jadwal Shif" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
	              <div class="card-header">
	                <h3 class="card-title"><div style="#007bff">Edit Jadwal Shift</div></h3>

	                <div class="card-tools">
	                </div>
	              </div>
	              <div class="card-body row">
	              	<div class="col-lg-2"></div>
	              	<div class="col-lg-8">
                        <el-form :model="formState" ref="formRef" label-position="top">
                            <el-form-item label="Nama" prop="nama" :rules="[ { required: true, message: 'Silahkan isi nama', trigger: blur } ]">
                                <el-input v-model="formState.nama" type="text" placeholder="Masukkan Nama"></el-input>
                            </el-form-item>
							<el-form-item label="Waktu" required>
								<el-col :span="12" class="mr-4">
									<el-form-item prop="mulai" :rules="[ { required: true, message: 'Silahkan pilih waktu mulai', trigger: change } ]">
										<el-time-picker v-model="formState.mulai" placeholder="Waktu Mulai" style="width: 100%;"></el-time-picker>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item prop="selesai" :rules="[ { required: true, message: 'Silahkan pilih waktu selesai', trigger: change } ]">
										<el-time-picker v-model="formState.selesai" placeholder="Waktu Selesai" style="width: 100%;"></el-time-picker>
									</el-form-item>
								</el-col>
							</el-form-item>

							<p>.</p>
							<el-button type="primary" class="mt-4" @click="prosesSubmit" :loading="loading">Simpan</el-button>
                        </el-form>
	              	</div>
	              	<div class="col-lg-2"></div>
	              </div>
	              <!-- /.card-body -->
	              <div class="card-footer" style="display: block;">
	              </div>
	              <!-- /.card-footer-->
            	</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader'

import { ref, computed } from 'vue'
import axios from 'axios'
import Swal from 'sweetalert2'
import router from '@/router'
import store from '@/store'
import * as dayjs from 'dayjs'
import { useRoute } from 'vue-router'

const route     = useRoute()
const loading   = ref(false)
const formState = computed(() => store.getters['jadwalShift/getJadwalShift'])

store.dispatch('jadwalShift/fetchJadwalShiftById', route.params.id)

const formRef = ref()

const prosesSubmit = () => {
    formRef.value.validate((valid) => {
        if (valid) {
            loading.value = true
            formState.value.mulai = dayjs(formState.value.mulai).format('YYYY-MM-DD HH:mm:ss')
            formState.value.selesai = dayjs(formState.value.selesai).format('YYYY-MM-DD HH:mm:ss')
            axios.put(`api/jadwal_shift/update/${route.params.id}`, formState.value)
            .then(() => {
            	Swal.fire({
            	  title: 'Berhasil!',
            	  text: 'Berhasil mengubah Data',
            	  icon: 'success',
            	  showConfirmButton: false,
            	  timer: 1500
            	})
            	loading.value = false
            	router.push('/jadwal-shift')
            })
            .catch(() => {
            	Swal.fire({
            	  title: 'Gagal!',
            	  text: 'Gagal mengubah data',
            	  icon: 'error',
            	  showConfirmButton: false,
            	  timer: 1500
            	})	
            	loading.value = false
            })
        } else {
            return false
        }
    })
}
</script>